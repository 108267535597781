.login {
  font-family: Montserrat, sans-serif;
  color: #5e5873;
  font-size: 14px;
}

@media (min-width: 360px) {
  .login-container {
    width: 100%;
  }

  .login-content {
    max-width: 95%;
    margin: 0 auto;
    padding: 20px;
  }
}

.login-container {
  display: grid;
}

@media (min-width: 992px) {
  .login-container {
    grid-template-columns: 2fr 1.5fr;
  }
}

@media (min-width: 1200px) {
  .login-container {
    grid-template-columns: 2fr 1fr;
  }
}

.login-hero {
  display: none;
  align-items: center;
  justify-content: center;
}

.login-hero img {
  max-width: 100%;
}

@media (min-width: 992px) {
  .login-hero {
    display: flex;
  }
}

@media (min-width: 768px) {

  .login-content {
    max-width: initial;
  }
}

.login-content h2 {
  font-weight: 500;
}

.login-footer {
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: center;
  align-content: center;
  column-gap: 1rem;
}

.login-footer .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
  border-color: #fff;
  color: #fff;
  border: none;
  border-radius: 0.358rem;
}

.login-footer .btn-facebook {
  background-color: #3b5998;
}

.login-footer .btn-twitter {
  background-color: #55acee;
}

.login-footer .btn-google {
  background-color: #dd4b39;
}

.login-footer .btn-github {
  background-color: #444;
}

.login-isNew a {
  color: #7367f0;
}

.header {
  position: sticky;
  padding: 2rem 0 0 2rem;
}

.divider {
  text-align: center;
  overflow: hidden;
}

.divider-text {
  padding: 0 0.5rem;
  position: relative;
  display: inline-block;
  font-size: 0.9375rem;
  text-align: center;
}

.divider-text::after,
.divider-text::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
}

.divider-text::before {
  right: 100%;
  border-top: 1px solid #ebe9f1;
}

.divider-text::after {
  left: 100%;
  border-top: 1px solid #ebe9f1;
}

.text-primary {
  color: #7367f0;
}

.login-welcome {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.login-create {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px
}